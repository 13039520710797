<template>
  <div class="bg-gradient-to-br from-[#11a263] to-[#1457bc] min-h-full relative flex flex-col">
    <Headers :title="$t('data')"></Headers>
    <div>
      <img class="w-241px h-270px absolute right-0 z-1" src="~@/assets/new/08.png" alt="" srcset="">
      <div class="px-24px text-23px font-medium pt-100px">{{ $t('d.des') }}</div>
      <div class="px-24px mt-30px text-32px font-bold font-Gilroy">{{lastFee}}</div>
      <div class="px-24px text-15px text-[#ffffffb3] mt-8px">{{ $t('d.h1') }}</div>
    </div>
    <div class="flex-1 mt-24px rounded-t-40px bg-gradient-to-b from-[#0d2e4e] to-[#0d0f1b] z-8 px-24px py-41px">
      <div class="grid grid-cols-2 gap-15px">
        <div class="p-20px rounded-16px bg-[#0ab17f33]">
          <div class="text-[#87FFDC] text-23px">12.00%</div>
          <div class="mt-6px text-13px text-[#ffffff80] font-Gilroy">当前建议买入滑点</div>
        </div>
        <div class="p-20px rounded-16px bg-[#0cc5ff33]">
          <div class="text-[#8FE4FF] text-23px">12.00%</div>
          <div class="mt-6px text-13px text-[#ffffff80] font-Gilroy">当前建议卖出滑点</div>
        </div>
        <div class="p-20px rounded-16px bg-[#4b34d74d]">
          <div class="text-[#D5CEFF] text-23px">{{ buyFee }}%</div>
          <div class="mt-6px text-13px text-[#ffffff80] font-Gilroy">{{ $t('d.h4') }}</div>
        </div>
        <div class="p-20px rounded-16px bg-[#fb32ff33]">
          <div class="text-[#FD99FF] text-23px">{{ extraBuyFee }}%</div>
          <div class="mt-6px text-13px text-[#ffffff80] font-Gilroy">{{ $t('d.h5') }}</div>
        </div>
        <div class="p-20px rounded-16px bg-[#fd781733]">
          <div class="text-[#FFBE8E] text-23px">{{ sellFee }}%</div>
          <div class="mt-6px text-13px text-[#ffffff80] font-Gilroy">{{ $t('d.h6') }}</div>
        </div>
        <div class="p-20px rounded-16px bg-[#f8c50f33]">
          <div class="text-[#F5E696] text-23px">{{ extraSellFee }}%</div>
          <div class="mt-6px text-13px text-[#ffffff80] font-Gilroy">{{ $t('d.h7') }}</div>
        </div>
      </div>

      <div class="bg-[#ffffff1a] h-40px rounded-16px text-center mt-15px leading-40px text-14px">
        <span class="text-[#ffffff]">{{ $t('d.h8') }}：</span><span>{{ transferFee }}%</span>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '@/mixins/index'
import Headers from "@/components/headers";
export default {
  components:{
    Headers
  },
  data() {
    return {
      feeInfo: [],
      buyFee: 0, //买入手续费
      sellFee: 0, //卖出手续费
      transferFee: 0, //转账手续费
      extraBuyFee: 0, //特别买入手续费
      extraSellFee: 0, //特别卖出手续费
      lastFee: 0, //当前价格
    }
  },
  mixins: [mixin],
  methods: {
    getfeeInfo() {
      this.UNC_contract.methods
        .feeInfo()
        .call()
        .then((res) => {
          this.feeInfo = res
          this.buyFee = res[0]
          this.sellFee = res[1]
          this.transferFee = res[2]
          this.extraBuyFee = res[3]
          this.extraSellFee = res[4]
          this.lastFee = (res[5] / 1e18).toFixed(4);
        })
    },
  },
  watch: {
    'global.account'() {
      this.UNC_contract = new this.global.web3.eth.Contract(
        this.poolabi.unc_abi,
        this.pool.iph
      )
      this.getfeeInfo()
    },
  },
}
</script>